<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
  
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link >
                    <small>Forgot Password?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 d-none">
            <span>New on our platform? </span>
            <b-link  >
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>

         

          <!-- divider -->
         

          <!-- social buttons -->
         
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
       this.$refs.loginValidation.validate().then(success => {
         useJwt.login({
            username: this.userEmail,
            password: this.password,
          })
            .then(response => {
              console.log(response);
            //     if (response.data.data)  {
            //         console.log('navigation to select company.');
              
            //        //  this.$refs.loginValidation.setErrors('test')
            //        this.$router.replace({name:"choosecompany",params: {
            //          companies:response.data.data,
            //            u : this.userEmail,
            // p : this.password,
                   
            //        }});
            //        return;
            //       };  
                  if (!response.data.accessToken)  {
                    console.log('handle error');
              this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Can't login. Please check your credentials`,
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
                   //  this.$refs.loginValidation.setErrors('test')
                   return;
                  };
                  console.log('response.data.',response.data);
                  // localStorage.setItem("clientToken",response.data.clientApiKey);
             
             
            // localStorage.setItem('clientCustomLoginSet',false)

              useJwt.setToken(response.data.accessToken);
              // useJwt.setRefreshToken(null);
              localStorage.setItem("userData", JSON.stringify(response.data));
              this.$router.replace("/").then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Welcome`,
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
              });

          //         useJwt.getModules({
            
          // }) .then(roleresponse => {

          //   console.log("Roles Initiated");
             
          //   var modules=roleresponse.data.data;
          //   var navItems=[];
          //   for (let index = 0; index < modules.length; index++) {
          //     const module = modules[index];
          //     console.log("Module:",module);
          //     var moduleFeatures=module.ClientModuleFeatures;
          //     console.log("ClientModuleFeatures:",moduleFeatures);
          //     if (moduleFeatures==undefined||moduleFeatures==null||moduleFeatures.length==0)
          //     {
          //       continue;
          //     }
          //     console.log("ClientModuleFeatures:",moduleFeatures);
          //     var navItem={header:module.ProjectModule.title};
          //     navItems.push(navItem);
          //     for (let elindex = 0; elindex < moduleFeatures.length; elindex++) {
          //       const featureelement = moduleFeatures[elindex];
          //       console.log("featureelement:",featureelement);
          //       var feature=featureelement.ModuleFeature;
          //       console.log("feature:",feature);
          //       var navFEtItem=
          //       {title:feature.title,
          //         icon:feature.icon,
          //        id:feature.id,
          //         route:feature.name };
          //         navItems.push(navFEtItem);
                
          //     }
               
          //   }

          //   console.log(navItems);
          //   localStorage.setItem("projectSettings",JSON.stringify(navItems) );
             
            


        

            
          //     const { userData } = response.data
             
          //           console.log(response.data);


          //             this.$router.replace("/").then(() => {
          //       this.$toast({
          //         component: ToastificationContent,
          //         position: "top-right",
          //         props: {
          //           title: `Welcome`,
          //           icon: "CoffeeIcon",
          //           variant: "success",
          //         },
          //       });
          //     });

             
          //     useJwt.setToken(response.data.accessToken)
          //     useJwt.setClientLogo(response.data.setting.logo)
          //     useJwt.setRefreshToken(response.data.refreshToken)
          //     localStorage.setItem('userData', JSON.stringify(response.data))
          //     this.$ability.update(userData.ability)
          //   localStorage.setItem('needstogoLogin',false)
          //     // ? This is just for demo purpose as well.
          //     // ? Because we are showing eCommerce app's cart items count in navbar
          //  this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

          //     // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          //     this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
          //       .then(() => {
          //         this.$toast({
          //           component: ToastificationContent,
          //           position: 'top-right',
          //           props: {
          //             title: `Welcome ${userData.fullName || userData.username}`,
          //             icon: 'CoffeeIcon',
          //             variant: 'success',
          //             text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
          //           },
          //         })
          //       })

          //     })
            })
            .catch(exc => {
              console.log(exc);
               

            //  this.$refs.loginForm.setErrors(error.response.data.error)
            })
        // if (success) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Form Submitted',
        //       icon: 'EditIcon',
        //       variant: 'success',
        //     },
        //   })
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
